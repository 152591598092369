import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Modal, message } from 'antd';
import taskStore from './store/taskStore';
import styles from '../src/css/Explor.module.scss'
import { useNavigate, useParams } from 'react-router'
import { observer } from "mobx-react";
import { useDebounce } from 'react-use'
import MediumEditor from 'medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/flat.css'
import { PlusSquareOutlined } from '@ant-design/icons';
import Selector from './components/Selector';
import planStore from './store/planStore';
import Heart from './components/Heart';
import AiHelps from './components/AiHelps';
import SelfAssessment from './components/SelfAssessment';
import ReadSteps from './components/ReadSteps';
import Notification from './img/notification.png'
import userStore from './store/userStore';
import Exercises from './components/Exercises';
import Survey from './components/Survey';

function Explor() {

  const params = useParams()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [challengeId, setChallengeId] = useState(null)
  const [strategyId, setStrategyId] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)


  const [showSubmitModal, setShowSubmitModal] = useState(false)

  const usedStrategy = taskStore.task.usedStrategies

  const chaOptions = usedStrategy && planStore.challengeOptions.map(item => {
    return {
      ...item,
      options: item.options.map(item => {
        return {
          ...item,
          disabled: usedStrategy.some(i => i.challengeId === item.value)
        }
      })
    }
  })

  const options = challengeId ? planStore.strategyOptions.filter(item => {
    return item.challengeId === challengeId
  }).map(item => {
    return {
      value: item.id,
      label: item.strategy
    }
  }) : []

  useEffect(() => {
    if (taskStore.task.article && !userStore?.profile?.isAdmin) {
      taskStore.startCount()
    }
    return () => taskStore.finishCount()
  }, [taskStore.task.article, userStore?.profile?.isAdmin])

  useEffect(() => {
    setStrategyId(null)
  }, [challengeId])

  const addStrategy = () => {
    if(usedStrategy.length >= 6) {
      message.warning('You can add up to 6 strategies.')
      return
    }
    setShowModal(true)
  }

  const  submitAddStrategy = async () => {
    if(!challengeId || !strategyId) {
      message.error('Please select challenge and strategy')
      return
    }
    setSubmitLoading(true)
    await taskStore.addStrategy(strategyId)
    setSubmitLoading(false)
    setChallengeId(null)
    setShowModal(false)
  }

  useDebounce(() => {
    taskStore.updateTaskDetail({
      note: taskStore.task.note
    })
    // console.log('!!')
  }, 2000, [taskStore.task.note])

  useEffect(() => {
    taskStore.getTaskDetail(params.id)
    planStore.fetchList()
  }, [])

  if (!taskStore.loading && taskStore.task.articleRichText) {

    // const MyExtension = MediumEditor.Extension.extend({
    //   name: 'myextension'
    // });
    
    // const myExt = new MyExtension();
    
    const editor = new MediumEditor('#middle', {
      placeholder: false,
      // disableEditing: true,
      toolbar: {
        buttons: [
            {
              name: 'bold',
              action: 'bold',
              aria: 'highlight',
              tagNames: ['b'],
              contentDefault: '<b>H</b>',
              classList: ['custom-class-b'],
              attrs: {
                  'data-custom-attr': 'attr-value-b'
              }
            },
            'underline',
        ]
      },
    });

    editor.execAction()

    
    editor.subscribe('editableInput', function (event, editable) {
      event.preventDefault();
      taskStore.updateTaskDetail({articleRichText: editable.innerHTML})
    });

    const ele = document.getElementById('middle')

    if (ele) {

      editor.on(ele, 'keypress', function(e) {
        console.log(e)
        e.preventDefault();
      })

      editor.on(ele, 'keydown', function(e) {
        console.log(e)
        e.preventDefault();
      })
    }
  
  }

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
      messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
  }

  useEffect(() => {
      scrollToBottom()
  }, [taskStore.task.aiHelps]);


 
  return (
    <div className={styles.page}>
      <div className={styles.title}>
        Strategy Exploration
      </div>

      <ReadSteps current={1} />

      <Spin spinning={taskStore.loading}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.leftTitle}>Self-Checklist</div>
            <div className={styles.leftTitle}>Planning Strategies <a className={styles.optionIcon} href="https://periwinkle-slash-7d2.notion.site/Self-regulated-reading-strategies-7e86c119b2e74f3e916f3ef9b7373c78?pvs=4" target="_blank">
                <img className={styles.notification} src={Notification} />
              </a></div>
            <div className={styles.strategy}>
              {
                taskStore.task.usedStrategies && taskStore.task.usedStrategies.map((item, index) => {
                  return (
                    <div key={index} className={styles.strategyItem}>
                      <div className={styles.itemTitle}>{item.strategy}</div>
                      <div>
                        <Heart disabled={taskStore.task.step !== 1} score={item.usedTimes} onChange={(e) => {
                          item.usedTimes = e
                          taskStore.updateTaskDetail({
                            usedStrategies: taskStore.task.usedStrategies
                          })
                        }}/>
                      </div>
                    </div>
                  )
                })
              }
              <Button size='large' onClick={addStrategy} className={styles.btn} icon={<PlusSquareOutlined style={{color: 'rgba(47, 178, 85, 1)'}}/>} type="dashed" >  Add one more（up to 6） </Button>
            </div>
            <div className={styles.noteWrapper}>
              <textarea disabled={taskStore.task.step !== 1} placeholder='note here...' className={styles.note} value={taskStore.task.note} onChange={e => taskStore.task.note = e.target.value}>
                {taskStore.task.note}
              </textarea>
            </div>
          </div>
          <div className={styles.middle} id="middle" dangerouslySetInnerHTML={{ __html: taskStore.task.articleRichText }}>
          </div>
          {
            userStore.profile && !userStore.profile.aiDisabled ? <div className={styles.right}>
              <AiHelps hideOperation={taskStore.task.exercises?.length > 0} aiHelps={taskStore.task.aiHelps}/>
            </div> : null
          }
        </div>
      </Spin>
      {
        userStore?.profile?.isAdmin ? <div className={styles.bottom}>
          { taskStore.task.exercises.length > 0 && <Button type="primary" size="large" className={styles.submitBtn} onClick={taskStore.generateExercises} loading={taskStore.generateLoading}>Show Exercise</Button> }
          { taskStore.task.selfAssessment && <Button type="primary" size="large" className={styles.submitBtn} onClick={() => taskStore.showAssessment = true} loading={taskStore.generateLoading}>Show assessment</Button> }
          { taskStore.task.survey && <Button type="primary" size="large" className={styles.submitBtn} onClick={() => taskStore.showSurvey = true} loading={taskStore.generateLoading}>Show survey</Button> }
        </div> : 
          <div className={styles.bottom}><Button type="primary" size="large" className={styles.submitBtn} onClick={taskStore.generateExercises} loading={taskStore.generateLoading}>Done</Button></div>
      }
      <Modal open={showModal} centered onOk={submitAddStrategy} onCancel={() => setShowModal(false)}>
          <Spin spinning={submitLoading}>
            <div className={styles.challenge}>
              <div className={styles.strategyTitle}>Challenge: </div>
              <Selector onChange={(e) => {setChallengeId(e)}} value={challengeId} placeholder="Challenge" width={450} options={chaOptions} />
            </div>

            <div className={styles.strategy}>
              <div className={styles.strategyTitle}>Strategy:</div>
              <Selector onChange={(e) => {setStrategyId(e)}} placeholder="Planning Strategy" value={strategyId} width={450} options={options} />
            </div>
          </Spin>
      </Modal>

      <SelfAssessment show={taskStore.showAssessment} />

      <Exercises show={taskStore.showExercises} />

      <Survey show={taskStore.showSurvey} />

    </div>
  )
}

export default observer(Explor);